.gridContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(1fr, 200px));
    grid-gap: 10px;
    grid-auto-flow: dense;
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 0 80px;
}

.card{
    color: white;
    text-align: center;
}

.card:nth-child(4n) {
    grid-column: span 2;
    grid-row: span 2;
}

.card img{
    width:100%;
    height:100%;
    object-fit: cover;
    border-radius: 1em;
}