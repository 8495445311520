html, body{
  box-sizing: border-box;
  margin:0;
  padding: 0;
  font-family: 'Roboto', serif;
  font-weight: 200;
  font-size: 1.1em;
  overflow-x: hidden;
}

.image{
  width: 300px;
  margin: 30px;
  border-radius: 15px;
}


body{
  background-color: #3F1859;
}

.header{
  top:0;
  background-color: rgba(224, 117, 255, 0.2);
  position: fixed;
  width: 100%;
  backdrop-filter: blur(20px);
  z-index: 10;
}

.header ul{
  padding: 0;
  margin: 0;
}

.header ul li{
  display: inline-block;
  color:white;
  padding: 20px;
  cursor: pointer;
}

.header ul li:hover{
  color:orange;
}


.background{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.Title{
  font-family: "High Tower Text", serif;
  background-image: url('./image31.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;
  font-size: 1em;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  color:white;
}

.paragraph{
  justify-content: center;
  text-align: center;
  padding:20px;
}

.flexSpaceBetween{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer{
  box-sizing: border-box;
  background-color: #80208C;
  color: white;
  padding: 20px;
  font-size: 10px;
}

.small{
  visibility: hidden;
}
.big{
  visibility: visible;
}

.hamburger{
  color:white;
  font-size: 30px;
  padding: 20px;
  cursor: pointer;
}

@media screen and (max-width: 600px){
  .flexSpaceBetween{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .Title{
    font-size: 10px;
  }
  .small{
    visibility: visible;
  }
  .big{
    visibility: hidden;
  }
}

.gallery{
  height: 500px;
  object-fit: contain;
}

.galleryCarousel{
  width:70%;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color:black;
  border-radius: 1em;
}


.shop{
  z-index: -1;
  position: fixed;
  left:0;
  top:0;
  background-image: url(./shop_image.jpg);
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  filter: blur(8px);
}

.copertina{
  width:300px;
  border-radius: 15px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
}

.copertinaContainer{
  height:100vh;
  gap:10px;
  align-items: center;
  justify-content: center;
  display:flex;
  color:white;
}

.description{
  font-family: "Nirmala UI", serif;
  text-align: left;
  width:50%;
}

.description button {
  font-family: "Nirmala UI", serif;
  font-size: .9em;
  padding:10px;
  border: transparent;
  border-radius: 1em;
  background-color:coral;
  color: white;
  margin:10px;
  cursor: pointer;
}

.glitch{
  animation: glitch .3s infinite;
}

@keyframes glitch {
  0%{
    text-shadow: #80208C 0 0 0;
  }
  20%{
    text-shadow: #80208C 3px 3px 0;
  }
  40%{
    text-shadow: #80208C 0 0 0;
  }
  60%{
    text-shadow: blue -3px -3px 0;
  }
  80%{
    text-shadow: #80208C -3px 3px 0;
  }
  100%{
    text-shadow: blue 3px -3px 0;
  }
}


@media screen and (max-width: 600px){
  .description{
    text-align: center;
    width:50%;
  }

  .copertinaContainer{
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
    height:100%;
    gap:30px;
  }

}

.email{
  color: coral;
}

a{
  color:white;
}